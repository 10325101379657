import { Link, useMatches } from "react-router";
import { useTranslation } from "react-i18next";
import { useMatchesData } from "~/components/hooks/useMatchesData";
import Flag from "react-flagpack";

type Props = {
  showLabel?: boolean;
};
export default function LanguageSelectListItems({ showLabel }: Props) {
  const matches = useMatches();
  const activeUrl = matches[matches?.length - 1].pathname;
  const data = useMatchesData("root");
  const { i18n } = useTranslation("translation");
  return (
    <>
      {Object.keys(data?.lngs).map((lng, index) => (
        <li key={data?.lngs[lng].nativeName + index}>
          <Link
            key={lng}
            style={{
              marginRight: 5,
              fontWeight: i18n.resolvedLanguage === lng ? "bold" : "normal",
            }}
            // reloadDocument={true}
            to={`${activeUrl}?lng=${lng}`}
          >
            <div className="avatar mr-3">
              <Flag
                code={lng === "en" ? "GBR" : lng.toUpperCase()}
                size="l"
                hasBorder={false}
                hasBorderRadius={false}
                gradient="real-linear"
              />
            </div>
            {data.lngs[lng].nativeName}
          </Link>
        </li>
      ))}
    </>
  );
}
